.active::before {
   position: absolute;
   content: "";
   width: 3px;
   height: 100%;
   background: #64ffda;
   left: 0;
   top: 0;
   z-index: 0;
   border-radius: 8px;
}

.track::after {
   position: absolute;
   content: "";
   width: 3px;
   height: 100%;
   background: #f9f9f9;
   left: 0;
   top: 0;
   z-index: -1;
}